'use client'

import { usePathname } from 'next/navigation'
import Script from 'next/script'
import { useEffect, useRef } from 'react'

const comscorePublisherId = '39588136'
export const comscoreConfig = {
    c1: '2',
    c2: comscorePublisherId,
    options: {
        enableFirstPartyCookie: true,
    },
}
function Comscore() {
    const pathname = usePathname()
    // don't fire on the first pageview
    const firstPath = useRef(pathname)
    useEffect(() => {
        // ignore the first pageview
        if (firstPath.current === pathname) {
            return
        }
        // clear first path, as we've navigated away
        firstPath.current = ''
        if (window.COMSCORE?.beacon) {
            console.log('comscore beacon for ', pathname)
            window.COMSCORE.beacon(comscoreConfig)
        }
    }, [pathname])
    return (
        <>
            {/* eslint-disable-next-line @next/next/no-before-interactive-script-outside-document */}
            <Script id="comscore" strategy="beforeInteractive">
                {`
                var _comscore = _comscore || []; _comscore.push(${JSON.stringify(comscoreConfig)});
                (function() {
                    var s = document.createElement("script"), el = document.getElementsByTagName("script")[0]; s.async = true; s.src = "https://sb.scorecardresearch.com/cs/${comscorePublisherId}/beacon.js";
                    el.parentNode.insertBefore(s, el);
                })();`}
            </Script>
        </>
    )
}

export default Comscore
